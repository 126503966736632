import PropTypes from 'prop-types';
import FacebookLogin, {FacebookLoginClient} from '@greatsumini/react-facebook-login';
import {useEffect} from 'react';
import {
  FACEBOOK_CHANNEL_LOGIN_CONFIGURATION,
  getChannelStatusConnectionColor,
  isAvailableFacebookConfigurationScopeIntoFacebookGrantedScope
} from '../../../../lib/channels-helper';
import {darkGrey} from '../../../../../../../../../shared/style/colors';
import AdministrationBusinessPageChannelsConnect from '../administration-business-page-channels-connect-component';

const AdministrationBusinessPageChannelsListFacebookItemComponent = ({
  channelType,
  dataTestId,
  facebookChannelConnected,
  facebookChannelError,
  facebookLogin,
  i18n,
  handleFacebookPageDisconnectionDialog,
  doStoreFacebookChannelRetrievedToken
}) => {
  const {labelButton, switchButtonColor} = getChannelStatusConnectionColor({
    facebookChannelConnected,
    facebookChannelError,
    i18n
  });

  useEffect(() => {
    FacebookLoginClient.loadSdk();
  }, []);

  const facebookIcon = `${ASSETS_PATH}/img/icon/channel/FACEBOOK.svg`;

  const {appId, scope, version} = FACEBOOK_CHANNEL_LOGIN_CONFIGURATION;

  const renderConnectionErrorMessage = () => {
    const [errorChannelConnectionKey] = facebookChannelError;

    return (
      <span style={{color: darkGrey}}>
        {i18n.t(
          `administration.business.pages.channels.${channelType}.${errorChannelConnectionKey}`
        )}
      </span>
    );
  };

  const facebookLoginCallback = (facebookLoginResponse) => {
    const {accessToken, grantedScopes} = facebookLoginResponse;
    if (
      !!grantedScopes &&
      isAvailableFacebookConfigurationScopeIntoFacebookGrantedScope(grantedScopes)
    ) {
      doStoreFacebookChannelRetrievedToken(accessToken);
    }
  };

  const onOpenFacebookPopup = ({target, facebookLoginProps}) => {
    if (target.checked) {
      // Initialize Facebook SDK with app ID to support multiple Facebook apps in the same page
      FacebookLoginClient.init({appId, version});
      facebookLoginProps.onClick();
    }
  };

  const renderDisconnectButton = () => {
    return (
      <AdministrationBusinessPageChannelsConnect
        icon={facebookIcon}
        title={i18n.t(`administration.business.pages.channels.${channelType}.itemTitle`)}
        channelConnected={facebookChannelConnected}
        {...{
          labelButton,
          switchButtonColor
        }}
        isSwitchButtonChecked={facebookChannelConnected}
        switchButtonDataTestId="facebook-page-disconnect-button"
        onHandleChange={handleFacebookPageDisconnectionDialog}
      />
    );
  };

  const renderChannelConnectionButton = (facebookLoginProps) => {
    return (
      <AdministrationBusinessPageChannelsConnect
        icon={facebookIcon}
        title={i18n.t(`administration.business.pages.channels.${channelType}.itemTitle`)}
        channelConnected={facebookChannelConnected}
        {...{
          labelButton,
          switchButtonColor
        }}
        isSwitchButtonChecked={facebookChannelConnected}
        switchButtonDataTestId={dataTestId}
        onHandleChange={(event) => onOpenFacebookPopup({target: event.target, facebookLoginProps})}
      />
    );
  };

  const renderFacebookLoginWrapperButton = () => (
    <FacebookLogin
      data-test-id="facebook-login-wrapper-button"
      onSuccess={facebookLoginCallback}
      onFail={() => {}}
      render={renderChannelConnectionButton}
      loginOptions={{
        return_scopes: true
      }}
      {...{appId, scope}}
    />
  );

  return (
    <div>
      <div
        data-test-id="channels-list-item"
        data-facebook-channel-stored-token={facebookLogin.authenticationToken ? 'true' : 'false'}
      >
        {facebookChannelConnected ? renderDisconnectButton() : renderFacebookLoginWrapperButton()}
      </div>
      {facebookChannelError ? renderConnectionErrorMessage() : null}
    </div>
  );
};

AdministrationBusinessPageChannelsListFacebookItemComponent.propTypes = {
  channelType: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  facebookChannelConnected: PropTypes.bool.isRequired,
  facebookChannelError: PropTypes.arrayOf(PropTypes.string),
  facebookLogin: PropTypes.objectOf(PropTypes.any),
  handleFacebookPageDisconnectionDialog: PropTypes.func.isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  doStoreFacebookChannelRetrievedToken: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelsListFacebookItemComponent;
